import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import ACT1 from './action-legality/ACT1.mdx';
import ACT2 from './action-legality/ACT2.mdx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "iv-actions-and-a-determination-of-their-legality"
    }}>{`IV. Actions and a Determination of their Legality`}</h1>
    <br />
    <ACT1 mdxType="ACT1" />
    <hr></hr>
    <br />
    <ACT2 mdxType="ACT2" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      